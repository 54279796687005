import React from "react";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import SEO from "../../components/SEO";
import Breadcrumbs from '../../components/Breadcrumbs'

function BlogIndexPage() {
  return (
    <Layout bottomBorder={true}>
      <SEO
        title={"Blog | Sell Your Home For Cash Sacramento"}
        keywords={"sell your home"}
        description={"Latest posts: Blog on Sell Your Home For Cash Sacramento"}
      />
      <div className="bg-white border-b border-gray-100">
        <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">

          <Breadcrumbs links={[{ to: '/', label: 'Home' }, { to: '/blog', label: 'Blog' }]} />

        </nav>
      </div>
      <div class="relative bg-gray-50 pt-12 pb-16 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative container mx-auto">
          <div class="text-center">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Blog
            </h2>
            <p class="mt-1 max-w-2xl mx-auto text-xl text-gray-500">
            Latest posts: Blog on Sell Your Home For Cash Sacramento
            </p>
          </div>
          <BlogRoll />
        </div>
      </div>
    </Layout>
  );
}
export default BlogIndexPage;
