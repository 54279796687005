import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'  

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div class="mt-12 max-w-lg mx-auto mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12 lg:max-w-none">
        {posts &&
          posts.map(({ node: post }) => (

            <div key={post.id}>
            <p class="text-sm text-gray-500">
            <time datetime={post.frontmatter.date}>
                {post.frontmatter.date}
                </time>
            </p>
            <Link className="mt-2 block"  to={post.fields.slug}> 
              <p class="text-xl font-semibold text-gray-900">
              {post.frontmatter.title}
              </p>
              <p class="mt-3 text-base text-gray-500">
              {post.excerpt.slice(0,130)}...</p>
            
            </Link>
            <div class="mt-3">
            <Link className="text-base font-semibold text-brand-600 hover:text-brand-500"  to={post.fields.slug}>
                Read full story
             </Link>
            </div>
          </div>
 
 
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost 
                author {
                  frontmatter {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
